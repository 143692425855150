import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Img from "../components/Img"
import PostHeaderStyles from "../components/styles/PostHeaderStyles"
import { PostMetaTags } from "../components/meta-tags"
import { Banner } from "../components/banner"
import { H1, H2, P } from "../components/page-elements"

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      fileAbsolutePath
      frontmatter {
        title
        slug
        date(formatString: "MMMM DD, YYYY")
        image {
          ...ImageFields
        }
        category
      }
      body
    }
  }
`

const ContentContainer = styled.div`
  ${tw`
    max-w-screen-md
    mx-auto
    px-page-gutter
    md:px-0
  `}

  p {
    font-family: var(--font-sans);
    color: var(--color-paragraph-text);
  }
`

const PostMeta = styled.div`
  ${tw`
    text-sm
    font-monospace
    my-2
  `}

  & > * :not(:last-child):after {
    content: "×";
    margin-left: 1rem;
    margin-right: 1rem;
    color: var(--color-primary);
    font-weight: bold;
    font-size: 1rem;
    font-style: normal;
  }
`

const Time = tw.time`
`

const PAGE_CONSTANTS = {
  title: "",
  header: "",
  description:
    "We're a small business providing websites for other small businesses at an affordable price of $0 down, $150 a month.",
}

function PostTemplate({ data: { mdx: post }, scope, pageContext }: unknown) {
  if (!post) {
    return <p>No Post Found? This should be a 404</p>
  }

  return (
    <>
      <Banner
        title={PAGE_CONSTANTS.title}
        header={PAGE_CONSTANTS.header}
        desctiption={PAGE_CONSTANTS.description}
      />
      <ContentContainer>
        <Img image={post.frontmatter.image} alt={post.frontmatter.title} />
        <PostHeaderStyles>
          <PostMetaTags post={post} />
          <H1 className="text-header">{post.frontmatter.title}</H1>
          <PostMeta>
            <Time dateTime={post.frontmatter.date}>
              {post.frontmatter.date}
            </Time>
            <span>{post.frontmatter.category.join(", ")}</span>
          </PostMeta>
        </PostHeaderStyles>
        <MDXRenderer
          scope={{
            ...scope,
          }}
        >
          {post.body}
        </MDXRenderer>
        {/* <ContentNav
          pathPrefix={pageContext.pathPrefix}
          prev={pageContext.prev}
          next={pageContext.next}
        /> */}
      </ContentContainer>
    </>
  )
}

export default PostTemplate
